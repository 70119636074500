import AxiosService from '@/services/axios-service';
import config from '@/config';

export default class VersionService {

  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  async apiVersion(): Promise<string>
  {
    const { api } = config;

    const uri = `${api}/version`;

    const version = await this.axiosService.get(uri);

    return version as string;
  }
}
