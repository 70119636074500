import { FilterField } from "@/models/filters";
import { GetCaseStatusValues } from "@/models/case-status"
import CaseLibraryService from "@/models/case-library"
import { Config } from "@/config";
import AxiosService from "@/services/axios-service";

export default class FilterService {
  constructor(private config: Config, private axiosService: AxiosService) {}

  public async getFilterDetails(): Promise<FilterField[]> {
    const caseLibraryService = new CaseLibraryService(this.axiosService);
    let libraries: { text: string; value: string }[];
    if (this.config.filters.findIndex(x => x.key === "library") >= 0){
      try {
        libraries = await caseLibraryService.GetLibraryValues();
      }
      catch{
        libraries = [];
      }
    }
    const filters = this.config.filters
      .map(filter => {
        if (filter.key === "status") {
          return {
            ...filter,
            type: "enum",
            values: GetCaseStatusValues()
          }
        } else if (filter.key === "library") {
          if (libraries.length) {
            return { ...filter, values: libraries };
          } else {
            return { ...filter, type: 'string' };
          }
        } else {
          return filter;
        }
      });

    return Promise.resolve(filters);
  }
}
