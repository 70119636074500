













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SnackbarOptions } from '@/models/form';

@Component
export default class EcSnackbar extends Vue {
  @Prop({ required: true }) options!: SnackbarOptions;

  static makeProgressOptions(messageToDisplay: string): SnackbarOptions {
    const result = {
      message: messageToDisplay,
      color: 'success',
      value: true,
      timeout: 0,
      closable: false,
      showProgress: true,
    } as SnackbarOptions;

    return result;
  }

  static makeSuccessfulOptions(messageToDisplay: string): SnackbarOptions {
    const result = {
      message: messageToDisplay,
      color: 'success',
      value: true,
      timeout: 5000,
      closable: true,
      showProgress: false,
    } as SnackbarOptions;

    return result;
  } 
  
  public static makeDefaultOptions(): SnackbarOptions {
    const result = {
      message: '',
      color: '',
      value: false,
      timeout: 5000,
      closable: true,
      showProgress: false,
    } as SnackbarOptions;

    return result;
  }

  static makeUnsuccessfulOptions(messageToDisplay: string): SnackbarOptions {
    const result = {
      message: messageToDisplay,
      color: 'error',
      value: true,
      timeout: 5000,
      closable: true,
      showProgress: false,
    } as SnackbarOptions;

    return result;
  }
}
