import AxiosService from "./axios-service";
import config from "@/config";

export interface AllStatusesConfig {
  statuses: SingleStatusConfig[];
}

export interface SingleStatusConfig {
  id: number;
  mandatoryNotes: boolean;
  metadata: StatusMetadata[];
}

export interface StatusMetadata {
  type: string;
  name: string;
  isRequired: boolean;
  values: MetadataValue[];
}

export interface MetadataValue {
  displayName: string;
  value: string;
}

export default class ConfigurationService {
  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async getStatusMetadataConfig() {
    const uri = `${config.api}/configuration/status-metadata`;

    const response = await this.axios.get(uri);
      
    return JSON.parse(response.json)
  }

  async saveStatusMetadataConfig(metadataConfig: AllStatusesConfig) {
    const uri = `${config.api}/configuration/status-metadata`;
    const body = { json: JSON.stringify(metadataConfig) };

    await Promise.all([
      this.axios.put(uri, body, { headers: { 'content-type': 'application/json' } }),
    ]);
  }
}
