import config from '@/config';
import AxiosService from './../services/axios-service';
import { Library } from './case-library.d';

export default class CaseLibraryService {
  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async GetLibraryValues(): Promise<{ text: string; value: string }[]> {
    const uri = `${config.hazcheckDetectApi}/libraries/`;
    return (await this.axios.get(uri) as Library)
      ._embedded.libraries
        .map(x => { return { text: x.name, value: x.name }} );
  }
}
