import { configLoading } from '@/config';

import AxiosService from './axios-service';
import AuthService from './auth-service';
import CaseService from './case-service';
import FilterService from './filter-service';
import UserService from './user-service';
import StatusService from './status-service';
import Notary from './notary';
import ScreeningViewService from './screening-view-service';
import EmailTemplateService from './email-template-service';
import EmailService from './email-service';
import ConfigurationService from './configuration-service';
import VersionService from './version-service';

export type Services = {
  AuthService: AuthService;
  CaseService: CaseService;
  FilterService: FilterService;
  UserService: UserService;
  StatusService: StatusService;
  Notary: Notary;
  ScreeningViewService: ScreeningViewService;
  EmailTemplateService: EmailTemplateService;
  EmailService: EmailService;
  ConfigurationService: ConfigurationService;
  VersionService: VersionService;
}

const services = {} as Services;

export const servicesLoading = configLoading.then((config) => {
  const authService = new AuthService();
  const axiosService = new AxiosService(authService);

  return Object.assign(services, {
    AuthService: authService,
    CaseService: new CaseService(axiosService),
    FilterService: new FilterService(config, axiosService),
    UserService: new UserService(axiosService),
    StatusService: new StatusService(axiosService),
    Notary: new Notary(axiosService),
    ScreeningViewService: new ScreeningViewService(axiosService),
    EmailTemplateService: new EmailTemplateService(axiosService),
    EmailService: new EmailService(axiosService),
    ConfigurationService: new ConfigurationService(axiosService),
    VersionService: new VersionService(axiosService)
  });
});

export default services;
