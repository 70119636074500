import { Case } from '@/models/case-maintenance.d';
import uriTemplate from 'url-template';
import config from '@/config';
import { HalLink, HalPagedResponse, HalResponse, PaginationOptions } from '@/models/hal.d';

import { CancelToken } from 'axios';
import AxiosService from './axios-service';
import { CaseView } from '@/models/case-maintenance';
import { Filter } from '@/models/filters';
import delay from '@/helpers/delay';
import { distinctBy } from '@/helpers/distinct';

export default class CaseService {
  private defaultPagenationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize,
  };

  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async readSingle(
    caseId: string,
    cancelToken?: CancelToken
  ): Promise<HalResponse<CaseView>> {
    const uri = `${config.api}/cases/${caseId}`;

    const _case = this.axios.get(uri, { cancelToken }) as Promise<HalResponse<Case>>;

    await Promise.all([
      _case,
      delay(1000)
    ]);

    return CaseService.mapCaseHal(await _case, c => new CaseView(c));
  }

  async listCases(
    options = this.defaultPagenationOptions,
    filter?: Filter[],
    assignment?: string | null,
    cancelToken?: CancelToken
  ): Promise<HalPagedResponse<CaseView, 'cases'>> {
    const { defaultPageSize, api } = config;
    const template = uriTemplate.parse(`${api}/cases/{?page,size,sort*,filter,assignment}`);

    const uri = template.expand({
      ...{ page: 1, size: defaultPageSize, sort: [] },
      ...options,
      filter: this.buildFilterQuery(filter),
      assignment: assignment,
    });

    const list = this.axios.get(uri, { cancelToken });

    await Promise.all([
      list,
      delay(2000)
    ]);

    const caseList = (await list) as HalPagedResponse<Case, 'cases'>;

    return CaseService.mapCaseHalList(caseList, (c) => new CaseView(c));
  }

  async listAllCases(
    filter?: Filter[],
    assignment?: string | null,
    progressCallback?: (count: number, total: number) => void,
    cancelToken?: CancelToken
  ): Promise<HalResponse<CaseView>[]> {
    const { api } = config;
    const template = uriTemplate.parse(`${api}/cases/{?page,size,sort*,filter,assignment}`);
    progressCallback = progressCallback || (() => { /*noop*/ });

    const uri = template.expand({
      ...{ page: 1, size: 100, sort: ['created-at,asc'] },
      filter: this.buildFilterQuery(filter),
      assignment: assignment,
    });

    let response = await this.axios.get(uri, { cancelToken }) as HalPagedResponse<Case, 'cases'>;
    let allCases = response._embedded.cases;

    await delay(100);

    progressCallback(0, 1);

    while (response._links && response._links.next) {
      const totalPages = Math.ceil(response.page.totalElements / response.page.size);
      progressCallback(response.page.number, totalPages);
      await delay(100);
      const nextHref = (response._links.next as HalLink).href;
      response = await this.axios.get(nextHref, { cancelToken }) as HalPagedResponse<Case, 'cases'>;
      allCases = allCases.concat(response._embedded.cases);
    }

    progressCallback(response.page.number, response.page.number);
    await delay(100);

    return allCases
      .filter(distinctBy("case-id"))
      .map(_case => CaseService.mapCaseHal(_case, x => new CaseView(x)));
  }

  private static mapCaseHalList(
    response: HalPagedResponse<Case, 'cases'>,
    map: (input: Case) => CaseView
  ): HalPagedResponse<CaseView, 'cases'> {
    return {
      ...response,
      _embedded: {
        cases: response._embedded.cases.map(x => ({
          _links: x._links,
          ...map(x)
        }))
      }
    }
  }

  private static mapCaseHal(
    response: HalResponse<Case>,
    map: (input: Case) => CaseView
  ): HalResponse<CaseView> {
    return {
      ...{ _links: response._links },
      ...map(response)
    }
  }

  private buildFilterQuery(
    filter?: Filter[],
  ): string | undefined {
    const filterConditions = filter?.reduce(
      (q, f) => {
        if (f.key && f.value) {
          q.push(`${f.key}=${f.value.replace(',', '%44')}`);
        }
        return q;
      },
      [] as string[],
    );

    return filterConditions?.length ? filterConditions.join(',') : undefined;
  }
}
