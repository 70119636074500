import { RouteConfig } from 'vue-router/types/router.d';
import { configLoading, Config } from '@/config';

import Home from '../views/Home.vue';
const Login = () => import(/* webpackChunkName: "login" */ `@/views/Login.vue`);
const Logout = () => import(/* webpackChunkName: "logout" */ `@/views/Logout.vue`);
const Cases = () => import(/* webpackChunkName: "cases" */ `@/views/Cases.vue`);
const Case = () => import(/* webpackChunkName: "case" */ `@/views/Case.vue`);
const EmailTemplates = () => import(/* webpackChunkName: "email" */ `@/views/EmailTemplates.vue`);
const EmailTemplate = () => import(/* webpackChunkName: "case" */ `@/views/EmailTemplate.vue`);
const Configuration = () => import(/* webpackChunkName: "configuration" */ `@/views/Configuration.vue`);

const caseManagementRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'root',
    component: Home,
    meta: {
      title: 'Welcome',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: 'Logout',
    },
  },
  {
    path: '/cases',
    name: 'cases',
    component: Cases,
    meta: {
      title: 'Cases',
      isSecure: true,
    },
  },
  {
    path: '/cases/:id',
    name: 'case',
    component: Case,
    meta: {
      title: 'Case',
      isSecure: true,
    },
  },
  {
    path: '/email-templates',
    name: 'email-templates',
    component: EmailTemplates,
    meta: {
      title: 'Email Templates',
      isSecure: true,
    },
  },
  {
    path: '/email-templates/create',
    name: 'email-template-create',
    component: EmailTemplate,
    meta: {
      title: 'Email Template',
      isSecure: true,
    },
  },
  {
    path: '/email-templates/:id',
    name: 'email-template',
    component: EmailTemplate,
    meta: {
      title: 'Email Template',
      isSecure: true,
    },
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: Configuration,
    meta: {
      title: 'Configuration',
      isSecure: true,
    },
  },
];

function notFoundRoute(config: Config): RouteConfig {
  return {
    path: '*',
    name: 'not-found',
    beforeEnter: (to) => {
      window.location.href = `${ config.hazcheckDetectUrl }/#/${to.path}`
    }
  };
}

function hazcheckDetectRoutes(config: Config): RouteConfig[] {
  return ['libraries', 'service-hooks', 'policies', 'screen-results'].map(name => ({
    path: `/${name}`,
    name: name,
    beforeEnter: () => {
      window.location.href = `${ config.hazcheckDetectUrl }/#/${name}`
    }
  }));
}

function analyticsRoutes(config: Config): RouteConfig[] {
  return ['analytics'].map(name => ({
    path: `/${name}`,
    name: name,
    beforeEnter: () => {
      window.location.href = `${ config.analyticsUrl }/#/${name}`
    }
  }));
}

export default configLoading
  .then((config) => [
    ...caseManagementRoutes,
    ...hazcheckDetectRoutes(config),
    ...analyticsRoutes(config),
    notFoundRoute(config)
  ]);
