export type Config = {
  api: string;
  hazcheckDetectApi: string;
  hazcheckDetectUrl: string;
  analyticsUrl: string;
  authentication: {
    audience: string;
    authority: string;
    clientId: string;
    postLogoutRedirectUri: string;
    redirectUri: string;
  };
  defaultPageSize: number;
  displayCaseDetails: { key: string; name: string; type?: string; sortable?: boolean; target?: string }[];
  caseStatus: { value: number; name: string }[];
  navigation: any[];
  filters: {
    key: string;
    text: string;
    type: 'enum' | 'string' | 'user' | 'date' | 'status';
    values?: string[];
  }[];
}

const config = {
  authentication: {},
  displayCaseDetails: [],
  caseStatus: [],
  navigation: []
} as unknown as Config;

function lowerCaseFirstLetter([first, ...rest]: string): string {
  return [first.toLowerCase(), ...rest].join('');
}

function mapKeys(obj: any, map: (key: string) => string): any {
  if (obj instanceof Array) {
    return obj.map(x => mapKeys(x, map));
  }

  if(!(obj instanceof Object)){
    return obj;
  }

  const keys = Object.keys(obj);

  return keys.reduce(
    (acc, key) => {
      const val = obj[key];
      return {
        ...acc,
        [map(key)]: typeof (val) === 'object' ? mapKeys(val, map) : val,
      };
    },
    {} as any,
  );
}

let envConfigFile = 'js/config-env.json';

if (window) {
  const query = new URLSearchParams(window.location.search);
  envConfigFile = query.get("config") || envConfigFile;
}

export const configLoading = Promise.all([
  fetch('js/config.json'),
  fetch(envConfigFile)
])
  .then(([baseResponse, envResponse]) =>{return Promise.all([baseResponse.json(), envResponse.json()])})
  .then(([baseJson, envJson]) => ({...baseJson, ...envJson}))
  .then((json) => mapKeys(json, lowerCaseFirstLetter))
  .then((json) => Object.assign(config, json)) as Promise<Config>;

export default config;
