import config from '@/config';
import uriTemplate from 'url-template';
import { User } from "@/models/case-maintenance.d";
import { HalPagedResponse, PaginationOptions } from '@/models/hal';
import AxiosService from "./axios-service";
import delay from '@/helpers/delay';
import { AxiosRequestConfig } from 'axios';
import { WillHandle } from './axios-service.d';
import { CaseView } from '@/models/case-maintenance';

export default class UserService {
  private defaultPagenationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize,
  };

  axios: AxiosService;

  private users: HalPagedResponse<User, 'users'> | null = null;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  public async getName(userId: string): Promise<string> {
    let users = this.users;
    if (!users) {
      users = await this.listUsers({ size: 100 });
    }

    const user = users._embedded.users.find(x => x.id === userId);

    if (user) return user.name;

    return userId;
  }

  public async assignCaseToUser(
    _case: CaseView,
    assignedTo: string | null,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {
    const uri = `${config.api}/cases/${_case['case-id']}/assignment`;
    const body = {
      'old-assigned-to': _case['assigned-to'],
      'assigned-to': assignedTo
    };

    await Promise.all([
        this.axios.post(uri, body, requestConfig),
        delay(1000)
    ]);
  }

  public async unassignCase(
    _case: CaseView,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {

    const uri = `${config.api}/cases/${_case['case-id']}/assignment`;
    const body = {
      'old-assigned-to': _case['assigned-to']
    };

    await Promise.all([
        this.axios.delete(uri, body, requestConfig),
        delay(1000)
    ]);
  }

  public async assignCaseIdToUser(
    _caseId: string,
    oldAssignedTo: string | null,
    assignedTo: string | null,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {
    const uri = `${config.api}/cases/${_caseId}/assignment`;
    const body = {
      'old-assigned-to': oldAssignedTo,
      'assigned-to': assignedTo
    };

    await Promise.all([
        this.axios.post(uri, body, requestConfig),
        delay(1000)
    ]);
  }

  public async unassignCaseId(
    _caseId: string,
    oldAssignedTo: string | null,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {

    const uri = `${config.api}/cases/${_caseId}/assignment`;
    const body = {
      'old-assigned-to': oldAssignedTo
    };

    await Promise.all([
        this.axios.delete(uri, body, requestConfig),
        delay(1000)
    ]);
  }

  public async listUsers(options = this.defaultPagenationOptions): Promise<HalPagedResponse<User, 'users'>> {
    if (this.users) return this.users;

    const { defaultPageSize, api } = config;
    const template = uriTemplate.parse(`${api}/users/{?page,size,sort*}`);

    const uri = template.expand({
      ...{page : 1, size: defaultPageSize, sort: ['name,asc'] },
      ...options,
    });

    const result = await this.axios.get(uri);

    this.users = result as HalPagedResponse<User, 'users'>;

    return this.users;
  }
}
