import './class-component-hooks'

import Vue from 'vue';
import plugins from '@/plugins/index';
import { servicesLoading } from '@/services/index';
import '@/filters/index';

import App from './App.vue';

import './registerServiceWorker';

import router from './router';
import store from './store';

Vue.config.productionTip = false;

Promise.all([
  servicesLoading,
  router
]).then(([services, router]) => {
  const vue = new Vue({
    router,
    store,
    ...plugins,
    provide: services,
    render: (h) => h(App),
  });

  store.services = services;

  (window as any).$$vue = vue;

  vue.$mount('#app');
});
