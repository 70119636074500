import Vue from "vue";

Vue.filter(
  'code-and-name',
  (value: { code: string; name: string }) => {
    if (!value) return value;

    if (value.code === value.name) return value.name;

    if (!value.name) return value.code;
    if (!value.code) return value.name;

    return `${value.name} (${value.code})`;
  }
);

