import { EmailTemplate } from "@/models/email-templates.d";
import AxiosService from "./axios-service";
import config from '@/config';
import uriTemplate from 'url-template';
import { HalPagedResponse, HalResponse } from "@/models/hal";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { WillHandle, CustomErrorMessageHandle } from "./axios-service.d";

export default class EmailTemplateService {
  sendTestEmail(
    recipient: string,
    subject: string,
    body: string,
    message?: string
    ): Promise<any> {
    const uri = `${config.api}/emails/`;
    const model = { recipient, subject, body, message  };

    return this.axiosService.post(uri, model);
  }

  sendCaseEmail(
    caseId: string,
    templateId: string,
    recipient: string,
    message?: string
  ): Promise<any> {
    const uri = `${config.api}/cases/${caseId}/emails`;
    const model = {
      recipient,
      templateId: templateId,
      message
    };

    return this.axiosService.post(uri, model);
  }

  updateEmailTemplate(
    id: string,
    model: EmailTemplate,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<EmailTemplate> {
    const uri = `${config.api}/email-templates/${id}`;

    return this.axiosService.put(uri, model, requestConfig) as Promise<HalResponse<EmailTemplate>>;
  }

  createEmailTemplate(
    model: EmailTemplate
  ): Promise<EmailTemplate> {
    const uri = `${config.api}/email-templates/`;

    const requestConfig = this.requestConfigForTemplateCreation();
    return this.axiosService.post(
      uri,
      model,
      requestConfig
      ) as Promise<HalResponse<EmailTemplate>>;
  }


  async readSingle(id: string): Promise<HalResponse<EmailTemplate>> {
    const uri = `${config.api}/email-templates/${id}`;

    return await this.axiosService.get(uri) as Promise<HalResponse<EmailTemplate>>;
  }

  constructor(private axiosService: AxiosService) { }

  async list(): Promise<HalPagedResponse<EmailTemplate, "email-templates">> {
    const uri = uriTemplate.parse(`${config.api}/email-templates{?page,size}`)
      .expand({ page: 1, size: 100});

    const list = await this.axiosService.get(uri) as HalPagedResponse<EmailTemplate, "email-templates">;
    list.page = {
      number: 1,
      totalElements: list._embedded['email-templates'].length,
      size: 100
    }

    return list;
  }

  async delete(id: string) {
    const uri = `${config.api}/email-templates/${id}`;

    try {
      await this.axiosService.delete(uri, undefined, {
        willHandle: (status) => { return status == 404 }
      });
    } catch {

    }
  }

  requestConfigForTemplateCreation = () => {
    return {
      getCustomErrorMessage : (response: AxiosResponse<any>) =>
      {
        if(response.status === 409)
        {
          const errorsArray = response.data?.errors as [any];
          if(errorsArray.length > 0)
            return errorsArray[0]?.message;
          return "The limit of 15 email templates has been reached. You cannot add another.";
        }
        return "";
      }
    } as AxiosRequestConfig & WillHandle & CustomErrorMessageHandle;
  }
}
